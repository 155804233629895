import React, { Component } from 'react';
import { getDocumentAsJSON, auth } from '../../firebase.js';
import placeholderImage from '../../assets/placeholder-image.jpg';

import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';

function initializeAuthButtons() {
    const offcanvasElement = document.querySelector('.offcanvas-info'); // Replace with your actual element ID
    if (!offcanvasElement) return;

    onAuthStateChanged(auth, (user) => {
        // Remove only the last child if it exists
        if (offcanvasElement.lastChild) {
            offcanvasElement.removeChild(offcanvasElement.lastChild);
        }

        if (user) {
            // User is signed in, display "Sign Out" button
            const signOutBtn = document.createElement('button');
            signOutBtn.className = 'other-editor-btn';
            signOutBtn.innerText = 'Sign Out';
            signOutBtn.onclick = (e) => handleSignOut();
            offcanvasElement.append(signOutBtn);
        } else {
            // No user is signed in, display "Login" button
            const loginBtn = document.createElement('button');
            loginBtn.className = 'other-editor-btn';
            loginBtn.innerText = 'Login';
            loginBtn.onclick = (e) => signInWithGoogle();
            offcanvasElement.append(loginBtn);
        }
    });
}

function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then((result) => {
            console.log("User signed in: ", result.user);
        })
        .catch((error) => {
            console.error("Error during sign-in: ", error);
        });
}

function handleSignOut() {
    signOut(auth)
        .then(() => {
            console.log("User signed out.");
        })
        .catch((error) => {
            console.error("Error during sign-out: ", error);
        });
}

class Content extends Component {
    constructor(props) {
        super(props);


    // Get the path after the hash
    let pathname = window.location.hash.split('#')[1];
    if (!pathname || pathname === '') pathname = 'home';

        this.state = {
            pathname,
            documentData: null,
            error: false,
        };
    }

    componentDidMount() {
        this.loadData();
        this.setupDropdownHover();
    }


componentDidUpdate(prevProps, prevState) {
    if (!document || !this.state.documentData) return; // Ensure documentData exists before proceeding

    // Get the path after the hash
    let pathname = window.location.hash.split('#')[1];
    if (!pathname || pathname === '') pathname = 'home';

        if (this.state.pathname != pathname)
    {
        console.log('new ' + pathname);
        console.log('old ' + this.state.pathname)
        this.setState({pathname}, () => {

            console.log('after setting state ' + this.state.pathname);
            this.loadData();
        });

        return;
    }


            const offcanvasElement = document.querySelector('.offcanvas-info');
        if(offcanvasElement && offcanvasElement.children.length === 6) {
            initializeAuthButtons();
        }




        const topBarElement = document.querySelector("div.navbar-other.ms-auto > ul");
        const hamburgerElement = document.querySelector(".hamburger");

    if(!topBarElement || !hamburgerElement) return;

        hamburgerElement.onclick = (e) => document.querySelector('.offcanvas-nav').classList.toggle('open');
        document.querySelector('.offcanvas-nav-close').onclick = (e) => document.querySelector('.offcanvas-nav').classList.toggle('open');

            topBarElement.querySelector('li').onclick = (e) => document.querySelector('.offcanvas-info').classList.toggle('open');

            document.querySelector('.offcanvas-info').onmouseleave = (e) => document.querySelector('.offcanvas-info').classList.toggle('open');

    if(!prevState || !prevState.documentData) {
        this.setupDropdownHover();
        return;
    }

    if (prevState.documentData !== this.state.documentData) {
        this.setupDropdownHover();

    }
}

    setupDropdownHover() {
        const dropdowns = [...document.querySelectorAll('.nav-item.dropdown')];

        dropdowns.forEach(dropdown => {
            const dropdownMenu = dropdown.querySelector('.dropdown-menu');
            let hideTimeout;

            const showMenu = () => {
                clearTimeout(hideTimeout); // Clear any pending hide actions
                dropdownMenu.style.display = 'block';
                dropdown.classList.add('show');
            };

            const hideMenu = () => {
                hideTimeout = setTimeout(() => { // Set a delay before hiding
                    dropdownMenu.style.display = 'none';
                    dropdown.classList.remove('show');
                }, 1000); // 300ms delay (adjust as needed)
            };

            dropdown.onmouseenter = showMenu;
            dropdown.onmouseleave = hideMenu;

            dropdownMenu.onmouseenter = showMenu;
            dropdownMenu.onmouseleave = hideMenu;
        });
    }

    signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log("User signed in: ", result.user);
            })
            .catch((error) => {
                console.error("Error during sign-in: ", error);
            });
    }

    async loadData() {
        try {
            console.log(this.state.pathname);
            const data = await getDocumentAsJSON('pages', this.state.pathname);
            this.setState({ documentData: data });
        } catch (err) {
            this.setState({ error: true });
        }
    }

    renderElement(elementData, elementPath) {
        if (!elementData || Object.keys(elementData).length === 0) return null;

        let { type = 'Empty', props = {}, children = [] } = elementData;

        let innerContent;

        switch (type) {
            case 'Section':
                let content = children.map((child, i) => this.renderElement(child, [...elementPath, i]));

                return (
                    <section 
                        {...props}
                    >
                    <div className="container">
                        {content}
                    </div>
                    </section>
                );

            case 'Card':
                const content2 = (children || []).map((child, i) => this.renderElement(child, [...elementPath, i]));

                return (
                    <div 
                        {...props}
                    >
                    <div className="card-body">
                        {content2}
                    </div>
                    </div>
                );

            case 'Row':
                if(!children) children = [];
                innerContent = (
                    <div className="row" >
                        {
                            children.map((child, i) => this.renderElement(child, [...elementPath, i]))
                        }
                    </div>
                );
                break;

            case 'Image':
                innerContent = <figure className="rounded"><img src={props.src || placeholderImage} alt="" /></figure>;
                break;

            case 'Text':
                innerContent = <p>{children || 'Placeholder text'}</p>;
                break;

            case 'Embed':
                return <div 
                    dangerouslySetInnerHTML={{ __html: children || '<p>Placeholder embed</p>' }}
                    {...props}
                />;
                break;

            default:
                break;
        }

        return (
            <div
                key={elementPath.join(' ')}
                {...props}
            >
                {innerContent} 
            </div>
        );
    }

    render() {
        const { documentData, error } = this.state;

        if (!documentData) {
            return <p>Loading...</p>;
        } else if (error) {
            return <p>An error has occurred.</p>;
        } else {
            return <>
                    {(documentData.sections || []).map((child, i) => this.renderElement(child, [i]))}
                </>;
        }
    }
}

export default Content;

