import React from 'react';
import { createPortal } from 'react-dom';
import '../../css/editor.css';

import { doc, setDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase.js'; 

import deleteImage from '../../util.js';

import Content from './Content.js';
import ImageUpload from './ImageUpload.js'

import placeholderImage from '../../assets/placeholder-image.jpg';


import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase.js'; // Update the path if needed

    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log("User signed in: ", result.user);
            })
            .catch((error) => {
                console.error("Error during sign-in: ", error);
            });
    };

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function getSubstringAfterLastHyphen(str) {
    const lastHyphenIndex = str.lastIndexOf('-');
    
    if (lastHyphenIndex === -1) {
        // Return an empty string if there's no hyphen in the string
        return '';
    }

    // Return the substring that comes after the last hyphen
    return str.substring(lastHyphenIndex + 1);
}

function moveItem(array, fromIndex, toIndex) {
    // Ensure indices are within bounds after removal
    const arrayLength = array.length;
    
    if (fromIndex < 0 || fromIndex >= arrayLength || toIndex < 0 || toIndex >= arrayLength) {
        console.error("Invalid indices", { fromIndex, toIndex, arrayLength });
        return;
    }

    // Remove the item from the original position
    const [item] = array.splice(fromIndex, 1);

    // If moving an item to an index larger than the new length, move it to the end
    if (toIndex >= array.length) {
        array.push(item);
    } else {
        // Insert the item at the new position
        array.splice(toIndex, 0, item);
    }
}


/**
 * EditContent Component
 * 
 * Extends the Content component to provide editing capabilities
 * for sections and elements within a page. Enables users to add, edit, and delete
 * various content types (Text, Image, Row, Embed) and save changes to a Firestore database.
 * The editing UI includes context menus, dot menus, and overlays for editing individual sections
 * and elements.
 */
class EditContent extends Content {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state, // Inherit the state from the parent Content component

            showContextMenu: false, // Controls the visibility of the context menu
            showEditOverlay: false, // Controls the visibility of the edit overlay
            contextMenuPosition: { x: 0, y: 0 }, // Position for the context menu

            selectedElementPath: [],
        };

    }

    /**
     * Saves the current state of the document to the Firestore database.
     * Updates the Firestore document with the current `documentData` state.
     * Uses Firestore's `setDoc` method with the merge option to update the document
     * while preserving existing data not being overwritten.
     */
    async saveData() {
        try {
            const docRef = doc(db, 'pages', this.state.pathname);  // Adjust the collection and document ID
            await setDoc(docRef, this.state.documentData, { merge: true });
            console.log('Document successfully written!');
            alert('Data successfully saved! ')
        } catch (error) {
            console.error('Error writing document: ', error);
            alert('Failed to save data.');
        }
    }

    /**
     * Lifecycle method that runs after the component has mounted.
     * Initializes the component and adds a "Save" button to the page's
     * top bar if it's not already present.
     */
    componentDidUpdate() {
        super.componentDidUpdate();

        if(!document) return;
            const saveButton = document.createElement('button');
            saveButton.className = 'other-editor-btn'
            saveButton.textContent = 'Save';
            saveButton.onclick = () => this.saveData();



            const topBarElement = document.querySelector("div.navbar-other.ms-auto > ul");
            const offcanvasElement = document.querySelector('.offcanvas-info');

        if(!topBarElement) return;
        if(!offcanvasElement) return;

            if (topBarElement && topBarElement.children.length === 2) {
                topBarElement.prepend(saveButton);
            }

        if(offcanvasElement && offcanvasElement.children.length === 6) {
            const loginBtn = document.createElement('button');
            loginBtn.className = 'other-editor-btn';
            loginBtn.innerText = 'Login';
            loginBtn.onclick = (e) => signInWithGoogle();
            offcanvasElement.append(loginBtn);
        }
    }

    /**
     * Moves an element up or down within its parent's children array.
     */

moveElement(elementPath, direction) {
    if (elementPath.length === 1) {
        // If the element path length is 1, the element is a direct child of documentData.sections
        const index = elementPath[0];
        const siblings = this.state.documentData.sections;

        if (index + direction < 0 || index + direction >= siblings.length) {
            console.error("Invalid move direction");
            return;
        }

        moveItem(siblings, index, index + direction);

        // Update the state with the modified sections
        this.setState({ documentData: { ...this.state.documentData, sections: siblings } });
    } else {
        // If the element is nested deeper, handle it using the parent path
        const parentPath = elementPath.slice(0, -1);
        const parentData = this.getElementDataFromPath(parentPath);

        if (!parentData || !parentData.children) {
            console.error("Invalid parent data or missing children array");
            return;
        }

        const siblings = parentData.children;
        const index = elementPath[elementPath.length - 1];

        if (index + direction < 0 || index + direction >= siblings.length) {
            console.error("Invalid move direction");
            return;
        }

        moveItem(siblings, index, index + direction);

        this.updateElementData(parentPath, { ...parentData, children: siblings });
    }
}

    handleContextMenuAction = (value) => {
            const { contextMenuElementPath } = this.state;
        const elementPath = contextMenuElementPath;

        switch (this.state.contextMenuType) {
            case 'newSection':
                this.addNewSection(value);
                break;


            case 'element':
                switch(value) {
                    case 'edit':
                        this.setState({
                            showEditOverlay: true,
                        });
                        break;
                    case 'delete':
                        this.deleteElementByPath(contextMenuElementPath);

                        break;
            case 'moveUp':
                this.moveElement(elementPath, -1);
                break;
            case 'moveDown':
                this.moveElement(elementPath, 1);
                break;
                }
 
                break;
            

            case 'newElement':

                const elementData = this.getElementDataFromPath(contextMenuElementPath);

                if (!elementData) {
                    console.warn("No valid element data found for the provided path.");
                    return null;
                }

                let { type = 'Empty', props = {}, children = [] } = elementData;

                if(!children || children.length == 0) children = [];

                let props2 = {};
                if(type == 'Row') {
                    props2.className = 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4';
                }

                if(value == 'Card') {
                    if(props2.className) props2.className += 'card shadow-lg';
                    else props2.className = 'card shadow-lg';
                }

                children.push({
                    type: value,
                    props: props2,
                    children: null,
                });

                this.updateElementData(contextMenuElementPath, {
                    type,
                    props,
                    children,
                });

                break;
            default:
                break;
        }

        this.setState({ showContextMenu: false });
    };

    addNewSection = (value) => {
        const tempData = { ...this.state.documentData };

        if (!tempData.sections) tempData.sections = [];

        if(value == 'Embed') {

        tempData.sections.push({
            type: value,
            children: null,
            props: {},
        });
        } else {

            tempData.sections.push({
                type: 'Section',
                children: [{
                    type: value,
                props: {
                    className: value == 'Card' ? 'card shadow-lg' : ''

                },
                children: null,
            }],
            props: {},
        });

        }
        this.setState({ documentData: tempData });
    };

    deleteElementByPath = (elementPath) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Remove the element at the final path index
    const finalIndex = elementPath[elementPath.length - 1];
    if (elementPath.length === 1) {
        // If the path length is 1, remove a top-level section
        updatedDocumentData.sections.splice(finalIndex, 1);
    } else {
        // Otherwise, remove a nested element
        currentElement.children.splice(finalIndex, 1);
    }

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};

    /**
     * Handles the click event on the plus button to add a new element.
     * Shows the context menu at the location of the click event.
     */
    enableContextMenu = (event, contextMenuType) => {
        const contextMenuPosition = { x: event.clientX, y: event.clientY };

        this.setState({
            showContextMenu: true, // Show the context menu
            contextMenuElementPath: this.state.selectedElementPath,
            contextMenuType,
            contextMenuPosition, // Set the position of the context menu
        });
    };

    /**
     * Closes the edit overlay.
     * Hides the edit overlay and clears the element or section being edited.
     */
    handleEditOverlayClose = () => {
        this.setState({ showEditOverlay: false, elementToEdit: null });
    };

    /**
     * Renders an element within a section.
     * Returns the JSX for an individual element (Text, Image, Row, Embed) within
     * a section. Includes a dot menu for each element that allows users to edit or delete it.
     */
    renderElement(elementData, elementPath) {
        if (!elementData || Object.keys(elementData).length === 0) return null;

        let { type = 'Empty', props = {}, children = [] } = elementData;
        const isHovered = this.state.selectedElementPath &&
            arraysEqual(this.state.selectedElementPath, elementPath);

        let innerContent;

        switch (type) {
            case 'Section':
                let content = children.map((child, i) => this.renderElement(child, [...elementPath, i]));

                if (content.length === 0) {
                    content.push(
                        <div className="container" key="empty-section">
                            <center>
                                <button
                                    key="plus-button"
                                    className="btn btn-outline-navy rounded-pill empty-section"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the Row's onClick from firing
                                        this.enableContextMenu(e, 'newElement');
                                    }}
                                >
                                    + Add Element 
                                </button>
                            </center>
                        </div>
                    );
                }

                return (
                    <section 
                        {...props}
                        key={elementPath.join(' ')}
                        onClick={(e) => this.enableContextMenu(e, 'element')}
                        style={isHovered ? { border: '2px solid blue' } : {}}
                        onMouseEnter={() => this.setState({ selectedElementPath: elementPath })}
                        onMouseLeave={() => this.setState({ selectedElementPath: elementPath.slice(0, -1) })}
                    >
                    <div className="container">
                        {content}
                    </div>
                    </section>
                );

            case 'Card':
                const content2 = (children || []).map((child, i) => this.renderElement(child, [...elementPath, i]));

                if (content2.length === 0) {
                    content2.push(
                        <div className="container" key="empty-section">
                            <center>
                                <button
                                    key="plus-button"
                                    className="btn btn-outline-navy rounded-pill empty-section"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the Row's onClick from firing
                                        this.enableContextMenu(e, 'newElement');
                                    }}
                                >
                                    + Add Element 
                                </button>
                            </center>
                        </div>
                    );
                }

                return (
                    <div 
                        {...props}
                        key={elementPath.join(' ')}
                        onClick={(e) => this.enableContextMenu(e, 'element')}
                        style={isHovered ? { border: '2px solid blue' } : {}}
                        onMouseEnter={() => this.setState({ selectedElementPath: elementPath })}
                        onMouseLeave={() => this.setState({ selectedElementPath: elementPath.slice(0, -1) })}
                    >
                    <div className="card-body">
                        {content2}
                    </div>
                    </div>
                );
                break;

            case 'Row':
                if(!children) children = [];
                innerContent = (
                    <div className="row" >
                        {
                            children.map((child, i) => this.renderElement(child, [...elementPath, i]))
                        }
                            <div className="col-1">
                                <button
                                    className="btn btn-outline-navy rounded-pill empty-section"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the Row's onClick from firing
                                        this.enableContextMenu(e, 'newElement');
                                    }}
                                >
                                    + Add Element
                                </button>
                            </div>
                    </div>
                );
                break;

            case 'Image':
                innerContent = <figure className="rounded"><img src={props.src || placeholderImage} alt="" /></figure>;
                break;

            case 'Text':
                innerContent = <p style={{wordBreak: 'break-word'}}>{children || 'Placeholder text'}</p>;
                break;

            case 'Embed':
                return <div 
                    dangerouslySetInnerHTML={{ __html: children || '<p>Placeholder embed</p>' }}
                    key={elementPath.join(' ')}
                    onMouseEnter={() => this.setState({ selectedElementPath: elementPath })}
                    onMouseLeave={() => this.setState({ selectedElementPath: elementPath.slice(0, -1) })}
                    onClick={(e) => this.enableContextMenu(e, 'element')}
                    style={isHovered ? { border: '1px solid blue' } : {}}
                    {...props}
                />;
                break;

            default:
                break;
        }

        return (
            <div
                key={elementPath.join(' ')}
                onMouseEnter={() => this.setState({ selectedElementPath: elementPath })}
                onMouseLeave={() => this.setState({ selectedElementPath: elementPath.slice(0, -1) })}
                onClick={(e) => this.enableContextMenu(e, 'element')}
                style={isHovered ? { border: '1px solid blue' } : {}}
                {...props}
            >
                {innerContent} 
            </div>
        );
    }

    getElementDataFromPath = (elementPath) => {
    let data = this.state.documentData?.sections;

    if (!data) return null;

    for (let i = 0; i < elementPath.length; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access top-level sections
            data = data[index];
        } else if (data?.children) {
            // Access child elements within sections
            data = data.children[index];
        } else {
            return null;
        }
    }

    return data || null;
};

updateElementData = (elementPath, newData) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Update the element with the new data
    const finalIndex = elementPath[elementPath.length - 1];
    if (elementPath.length === 1) {
        // If the path length is 1, we are updating a top-level section
        updatedDocumentData.sections[finalIndex] = {
            ...currentElement,
            ...newData,
        };
    } else {
        // Otherwise, update a nested element
        currentElement.children[finalIndex] = {
            ...currentElement.children[finalIndex],
            ...newData,
        };
    }

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};

    /**
     * Renders the context menu.
     * Returns the JSX for the context menu, which allows users to add or modify elements.
     */
    renderContextMenu() {
        if (!this.state.showContextMenu) return null;

        const { x, y } = this.state.contextMenuPosition;
        const elementPath = this.state.contextMenuElementPath;

        let menuItems;

        switch (this.state.contextMenuType) {
            case 'newSection':
                menuItems = [
                <li onClick={() => this.handleContextMenuAction('Text')}>Text</li>,
                <li onClick={() => this.handleContextMenuAction('Image')}>Image</li>,
                <li onClick={() => this.handleContextMenuAction('Row')}>Row</li>,
                <li onClick={() => this.handleContextMenuAction('Card')}>Card</li>,
                <li onClick={() => this.handleContextMenuAction('Embed')}>Embed</li>,

                ];
                break;
            case 'newElement':

                menuItems = [
                <li onClick={() => this.handleContextMenuAction('Text')}>Text</li>,
                <li onClick={() => this.handleContextMenuAction('Image')}>Image</li>,
                <li onClick={() => this.handleContextMenuAction('Row')}>Row</li>,
                <li onClick={() => this.handleContextMenuAction('Card')}>Card</li>,
                <li onClick={() => this.handleContextMenuAction('Embed')}>Embed</li>,
                ];
                break;
            case 'element':

                menuItems = [
                <li onClick={() => this.handleContextMenuAction('edit')}>Edit</li>,
                <li onClick={() => this.handleContextMenuAction('moveUp')}>Move Up</li>,
                <li onClick={() => this.handleContextMenuAction('moveDown')}>Move Down</li>,
                <li onClick={() => this.handleContextMenuAction('delete')}>Delete</li>,
                ];
                break;
        }

        return createPortal(
            <div
                className="context-menu"
                style={{ top: `${y - 20 + window.scrollY}px`, left: `${x - 20}px` }}
                onMouseLeave={() => this.setState({ showContextMenu: false })}
            >
                <ul>{menuItems}</ul>
            </div>,
            document.body
        );
    }




setElementPropByPath = (elementPath, propName, propValue) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Update the prop at the final path index
    const finalIndex = elementPath[elementPath.length - 1];
    if (elementPath.length === 1) {
        // If the path length is 1, update a top-level section
        updatedDocumentData.sections[finalIndex].props[propName] = propValue;
    } else {
        // Otherwise, update a nested element
        currentElement.children[finalIndex].props[propName] = propValue;
    }

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};



setElementChildrenByPath = (elementPath, newChildren) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Update the children at the final path index
    const finalIndex = elementPath[elementPath.length - 1];
    if (elementPath.length === 1) {
        // If the path length is 1, update a top-level section
        updatedDocumentData.sections[finalIndex].children = newChildren;
    } else {
        // Otherwise, update a nested element
        currentElement.children[finalIndex].children = newChildren;
    }

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};


toggleElementClassByPath = (elementPath, className, action) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Get the target element
    const finalIndex = elementPath[elementPath.length - 1];
    let targetElement;
    if (elementPath.length === 1) {
        targetElement = updatedDocumentData.sections[finalIndex];
    } else {
        targetElement = currentElement.children[finalIndex];
    }

    // Safely modify the className property
    const currentClassName = targetElement.props.className || '';

    // Split the className into an array for manipulation
    let classList = currentClassName.split(' ').filter(Boolean);

    if (action === 'add' && !classList.includes(className)) {
        classList.push(className); // Add the class if not already present
    } else if (action === 'remove' && classList.includes(className)) {
        classList = classList.filter(cls => cls !== className); // Remove the class if present
    }

    // Update the className property with modified classList
    targetElement.props.className = classList.join(' ');

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};





setElementColClassByPath = (elementPath, screenSize, newColClass) => {
    // Create a deep copy of the documentData state to avoid mutating state directly
    let updatedDocumentData = JSON.parse(JSON.stringify(this.state.documentData));

    // Traverse the documentData using the elementPath
    let currentElement = updatedDocumentData.sections;

    for (let i = 0; i < elementPath.length - 1; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            currentElement = currentElement[index];
        } else {
            // Access the nested children
            currentElement = currentElement.children[index];
        }
    }

    // Get the target element
    const finalIndex = elementPath[elementPath.length - 1];
    let targetElement;
    if (elementPath.length === 1) {
        targetElement = updatedDocumentData.sections[finalIndex];
    } else {
        targetElement = currentElement.children[finalIndex];
    }

    // Safely modify the className property
    const currentClassName = targetElement.props.className || '';

    // Split the className into an array for manipulation
    let classList = currentClassName.split(' ').filter(Boolean);

    // Determine the prefix based on the screen size, default to 'col-' if screenSize is empty
    const colClassPrefix = screenSize ? `col-${screenSize}-` : `col-`;

    // Update the regex to match both 'col-*' and 'col-xx-*' patterns correctly
    const colClassRegex = new RegExp(`^${colClassPrefix}\\d+$`);

    // Remove existing col-xx-* classes for the specified screen size or default
    classList = classList.filter(cls => !colClassRegex.test(cls));

    // Add the new col-xx-* class
    if (!classList.includes(colClassPrefix + newColClass)) {
        classList.push(colClassPrefix + newColClass);
    }

    // Update the className property with the modified classList
    targetElement.props.className = classList.join(' ');

    // Update the state with the modified documentData
    this.setState({ documentData: updatedDocumentData });
};


getElementColClassByPath = (elementPath, screenSize) => {
    // Navigate to the target element in documentData using the elementPath
    let targetElement = this.state.documentData.sections;

    for (let i = 0; i < elementPath.length; i++) {
        const index = elementPath[i];
        if (i === 0) {
            // Access the top-level section
            targetElement = targetElement[index];
        } else {
            // Access the nested children
            targetElement = targetElement.children[index];
        }
    }

    // Check if targetElement is valid and has a className property
    if (!targetElement || !targetElement.props || !targetElement.props.className) {
        return null;
    }

    // Retrieve the current className and split it into an array
    const currentClassName = targetElement.props.className || '';

    const classList = currentClassName.split(' ').filter(Boolean);

    // Construct the prefix for the specific screen size or default size
    const colClassPrefix = screenSize ? `col-${screenSize}-` : `col-`;

    // Update the regex to match both 'col-*' and 'col-xx-*' patterns correctly
    const colClassRegex = new RegExp(`^${colClassPrefix}\\d+$`);

    // Find and return the class that matches the regex for the specific screen size
    const colClass = classList.find(cls => colClassRegex.test(cls));

    return colClass || null;
};









    /**
     * Renders the edit overlay.
     * Returns the JSX for the edit overlay, which allows users to modify
     * the properties of a section or element.
     */

renderEditOverlay() {
    if (!this.state.showEditOverlay) return null;

    const { contextMenuElementPath } = this.state;
    const elementData = this.getElementDataFromPath(contextMenuElementPath);

    if (!elementData) {
        console.warn("No valid element data found for the provided path.");
        return null;
    }

    const { type = 'Empty', props = {}, children = [] } = elementData;

        let editContent;
        switch (type) {
            case 'Section':
                editContent = (
                    <>
                        <label htmlFor="bg">Background Color</label>
                        <select
                            name="bg"
                            id="bg"
                            defaultValue={props.className || 'bg-pale-gray'}
                            onChange={(e) => {
                                this.setElementPropByPath(contextMenuElementPath, 'className', e.target.value)
                            }}
                        >
                            {/* Add options for different background colors */}
                            {/* (Options omitted for brevity) */}
                    <option value="bg-amazon">Amazon</option>
                    <option value="bg-aqua">Aqua</option>
                    <option value="bg-ash">Ash</option>
                    <option value="bg-auto">Auto</option>
                    <option value="bg-black">Black</option>
                    <option value="bg-blue">Blue</option>
                    <option value="bg-body">Body</option>
                    <option value="bg-color-after-donate-pink">Color After Donate Pink</option>
                    <option value="bg-color-before-donate-pink">Color Before Donate Pink</option>
                    <option value="bg-color-donate-pink">Color Donate Pink</option>
                    <option value="bg-color-hover-after-donate-pink">Color Hover After Donate Pink</option>
                    <option value="bg-color-hover-before-donate-pink">Color Hover Before Donate Pink</option>
                    <option value="bg-color-hover-donate-pink">Color Hover Donate Pink</option>
                    <option value="bg-content">Content</option>
                    <option value="bg-cover">Cover</option>
                    <option value="bg-danger">Danger</option>
                    <option value="bg-dark">Dark</option>
                    <option value="bg-donate-pink">Donate Pink</option>
                    <option value="bg-dot">Dot</option>
                    <option value="bg-facebook">Facebook</option>
                    <option value="bg-fuchsia">Fuchsia</option>
                    <option value="bg-full">Full</option>
                    <option value="bg-gradient">Gradient</option>
                    <option value="bg-gradient-aqua">Gradient Aqua</option>
                    <option value="bg-gradient-ash">Gradient Ash</option>
                    <option value="bg-gradient-blue">Gradient Blue</option>
                    <option value="bg-gradient-dark">Gradient Dark</option>
                    <option value="bg-gradient-fuchsia">Gradient Fuchsia</option>
                    <option value="bg-gradient-grape">Gradient Grape</option>
                    <option value="bg-gradient-gray">Gradient Gray</option>
                    <option value="bg-gradient-green">Gradient Green</option>
                    <option value="bg-gradient-hot-red">Gradient Hot Red</option>
                    <option value="bg-gradient-leaf">Gradient Leaf</option>
                    <option value="bg-gradient-navy">Gradient Navy</option>
                    <option value="bg-gradient-orange">Gradient Orange</option>
                    <option value="bg-gradient-pale-fuchsia">Gradient Pale Fuchsia</option>
                    <option value="bg-gradient-pale-grape">Gradient Pale Grape</option>
                    <option value="bg-gradient-pale-pink">Gradient Pale Pink</option>
                    <option value="bg-gradient-pale-purple">Gradient Pale Purple</option>
                    <option value="bg-gradient-pale-red">Gradient Pale Red</option>
                    <option value="bg-gradient-pale-sky">Gradient Pale Sky</option>
                    <option value="bg-gradient-pale-yellow">Gradient Pale Yellow</option>
                    <option value="bg-gradient-pink">Gradient Pink</option>
                    <option value="bg-gradient-primary">Gradient Primary</option>
                    <option value="bg-gradient-purple">Gradient Purple</option>
                    <option value="bg-gradient-red">Gradient Red</option>
                    <option value="bg-gradient-reverse-aqua">Gradient Reverse Aqua</option>
                    <option value="bg-gradient-reverse-ash">Gradient Reverse Ash</option>
                    <option value="bg-gradient-reverse-blue">Gradient Reverse Blue</option>
                    <option value="bg-gradient-reverse-dark">Gradient Reverse Dark</option>
                    <option value="bg-gradient-reverse-fuchsia">Gradient Reverse Fuchsia</option>
                    <option value="bg-gradient-reverse-grape">Gradient Reverse Grape</option>
                    <option value="bg-gradient-reverse-gray">Gradient Reverse Gray</option>
                    <option value="bg-gradient-reverse-green">Gradient Reverse Green</option>
                    <option value="bg-gradient-reverse-hot-red">Gradient Reverse Hot Red</option>
                    <option value="bg-gradient-reverse-leaf">Gradient Reverse Leaf</option>
                    <option value="bg-gradient-reverse-navy">Gradient Reverse Navy</option>
                    <option value="bg-gradient-reverse-orange">Gradient Reverse Orange</option>
                    <option value="bg-gradient-reverse-pale-purple">Gradient Reverse Pale Purple</option>
                    <option value="bg-gradient-reverse-pale-sky">Gradient Reverse Pale Sky</option>
                    <option value="bg-gradient-reverse-pink">Gradient Reverse Pink</option>
                    <option value="bg-gradient-reverse-primary">Gradient Reverse Primary</option>
                    <option value="bg-gradient-reverse-purple">Gradient Reverse Purple</option>
                    <option value="bg-gradient-reverse-red">Gradient Reverse Red</option>
                    <option value="bg-gradient-reverse-sky">Gradient Reverse Sky</option>
                    <option value="bg-gradient-reverse-soft-blue">Gradient Reverse Soft Blue</option>
                    <option value="bg-gradient-reverse-vintage-blue">Gradient Reverse Vintage Blue</option>
                    <option value="bg-gradient-reverse-violet">Gradient Reverse Violet</option>
                    <option value="bg-gradient-reverse-yellow">Gradient Reverse Yellow</option>
                    <option value="bg-gradient-sky">Gradient Sky</option>
                    <option value="bg-gradient-soft-blue">Gradient Soft Blue</option>
                    <option value="bg-gradient-vintage-blue">Gradient Vintage Blue</option>
                    <option value="bg-gradient-violet">Gradient Violet</option>
                    <option value="bg-gradient-yellow">Gradient Yellow</option>
                    <option value="bg-grape">Grape</option>
                    <option value="bg-gray">Gray</option>
                    <option value="bg-green">Green</option>
                    <option value="bg-header-grape">Header Grape</option>
                    <option value="bg-hot-red">Hot Red</option>
                    <option value="bg-hover-donate-pink">Hover Donate Pink</option>
                    <option value="bg-info">Info</option>
                    <option value="bg-leaf">Leaf</option>
                    <option value="bg-light">Light</option>
                    <option value="bg-line">Line</option>
                    <option value="bg-main-sky">Main Sky</option>
                    <option value="bg-map">Map</option>
                    <option value="bg-navy">Navy</option>
                    <option value="bg-opacity-10">Opacity 10</option>
                    <option value="bg-opacity-100">Opacity 100</option>
                    <option value="bg-opacity-25">Opacity 25</option>
                    <option value="bg-opacity-50">Opacity 50</option>
                    <option value="bg-opacity-75">Opacity 75</option>
                    <option value="bg-orange">Orange</option>
                    <option value="bg-overlay">Overlay</option>
                    <option value="bg-overlay-300">Overlay 300</option>
                    <option value="bg-overlay-400">Overlay 400</option>
                    <option value="bg-overlay-gradient">Overlay Gradient</option>
                    <option value="bg-overlay-light-300">Overlay Light 300</option>
                    <option value="bg-overlay-light-400">Overlay Light 400</option>
                    <option value="bg-overlay-light-500">Overlay Light 500</option>
                    <option value="bg-pale-aqua">Pale Aqua</option>
                    <option value="bg-pale-ash">Pale Ash</option>
                    <option value="bg-pale-blue">Pale Blue</option>
                    <option value="bg-pale-dark">Pale Dark</option>
                    <option value="bg-pale-fuchsia">Pale Fuchsia</option>
                    <option value="bg-pale-grape">Pale Grape</option>
                    <option value="bg-pale-gray">Pale Gray</option>
                    <option value="bg-pale-green">Pale Green</option>
                    <option value="bg-pale-leaf">Pale Leaf</option>
                    <option value="bg-pale-light">Pale Light</option>
                    <option value="bg-pale-navy">Pale Navy</option>
                    <option value="bg-pale-orange">Pale Orange</option>
                    <option value="bg-pale-pink">Pale Pink</option>
                    <option value="bg-pale-primary">Pale Primary</option>
                    <option value="bg-pale-purple">Pale Purple</option>
                    <option value="bg-pale-red">Pale Red</option>
                    <option value="bg-pale-sky">Pale Sky</option>
                    <option value="bg-pale-violet">Pale Violet</option>
                    <option value="bg-pale-white">Pale White</option>
                    <option value="bg-pale-yellow">Pale Yellow</option>
                    <option value="bg-pink">Pink</option>
                    <option value="bg-primary">Primary</option>
                    <option value="bg-purple">Purple</option>
                    <option value="bg-red">Red</option>
                    <option value="bg-secondary">Secondary</option>
                    <option value="bg-sky">Sky</option>
                    <option value="bg-soft-aqua">Soft Aqua</option>
                    <option value="bg-soft-ash">Soft Ash</option>
                    <option value="bg-soft-blue">Soft Blue</option>
                    <option value="bg-soft-dark">Soft Dark</option>
                    <option value="bg-soft-fuchsia">Soft Fuchsia</option>
                    <option value="bg-soft-grape">Soft Grape</option>
                    <option value="bg-soft-gray">Soft Gray</option>
                    <option value="bg-soft-green">Soft Green</option>
                    <option value="bg-soft-leaf">Soft Leaf</option>
                    <option value="bg-soft-light">Soft Light</option>
                    <option value="bg-soft-navy">Soft Navy</option>
                    <option value="bg-soft-orange">Soft Orange</option>
                    <option value="bg-soft-pink">Soft Pink</option>
                    <option value="bg-soft-primary">Soft Primary</option>
                    <option value="bg-soft-purple">Soft Purple</option>
                    <option value="bg-soft-red">Soft Red</option>
                    <option value="bg-soft-sky">Soft Sky</option>
                    <option value="bg-soft-violet">Soft Violet</option>
                    <option value="bg-soft-white">Soft White</option>
                    <option value="bg-soft-yellow">Soft Yellow</option>
                    <option value="bg-success">Success</option>
                    <option value="bg-transparent">Transparent</option>
                    <option value="bg-vintage-blue">Vintage Blue</option>
                    <option value="bg-violet">Violet</option>
                    <option value="bg-warning">Warning</option>
                    <option value="bg-white">White</option>
                    <option value="bg-yellow">Yellow</option>
                        </select>
                    </>
                );
                break;

            case 'Text':

                editContent = (
                    <textarea
                        defaultValue={children || ''}
                        rows="20"
                        cols="100"
                        onChange={(e) => this.setElementChildrenByPath(contextMenuElementPath, e.target.value)}
                    />
                );
                break;

            case 'Image':
                editContent = <>
                    {elementData.props.src 
                        ? <button onClick={(e) => {
                            this.setElementPropByPath(contextMenuElementPath, 'src', null);
                            deleteImage(elementData.props.src);
                        }
                        }>Delete Current Image</button> 
                        : <ImageUpload storage={storage} onUploadSuccess={(url) => this.setElementPropByPath(contextMenuElementPath, 'src', url)}/>
                    }
                </>;
                break;

            case 'Embed':
                editContent = (
                    <textarea
                        defaultValue={children || ''}
                        rows="20"
                        cols="100"
                        placeholder="HTML Content"
                        onChange={(e) => this.setElementChildrenByPath(contextMenuElementPath, e.target.value)}
                    />
                );
                break;
            case 'Card':

                        editContent = <select
                            name="bg"
                            id="bg"
                            defaultValue={props.className || 'bg-pale-gray'}
                            onChange={(e) => {
                                this.setElementPropByPath(contextMenuElementPath, 'className', e.target.value)
                            }}
                        >
                            {/* Add options for different background colors */}
                            {/* (Options omitted for brevity) */}
                    <option value="bg-amazon">Amazon</option>
                    <option value="bg-aqua">Aqua</option>
                    <option value="bg-ash">Ash</option>
                    <option value="bg-auto">Auto</option>
                    <option value="bg-black">Black</option>
                    <option value="bg-blue">Blue</option>
                    <option value="bg-body">Body</option>
                    <option value="bg-color-after-donate-pink">Color After Donate Pink</option>
                    <option value="bg-color-before-donate-pink">Color Before Donate Pink</option>
                    <option value="bg-color-donate-pink">Color Donate Pink</option>
                    <option value="bg-color-hover-after-donate-pink">Color Hover After Donate Pink</option>
                    <option value="bg-color-hover-before-donate-pink">Color Hover Before Donate Pink</option>
                    <option value="bg-color-hover-donate-pink">Color Hover Donate Pink</option>
                    <option value="bg-content">Content</option>
                    <option value="bg-cover">Cover</option>
                    <option value="bg-danger">Danger</option>
                    <option value="bg-dark">Dark</option>
                    <option value="bg-donate-pink">Donate Pink</option>
                    <option value="bg-dot">Dot</option>
                    <option value="bg-facebook">Facebook</option>
                    <option value="bg-fuchsia">Fuchsia</option>
                    <option value="bg-full">Full</option>
                    <option value="bg-gradient">Gradient</option>
                    <option value="bg-gradient-aqua">Gradient Aqua</option>
                    <option value="bg-gradient-ash">Gradient Ash</option>
                    <option value="bg-gradient-blue">Gradient Blue</option>
                    <option value="bg-gradient-dark">Gradient Dark</option>
                    <option value="bg-gradient-fuchsia">Gradient Fuchsia</option>
                    <option value="bg-gradient-grape">Gradient Grape</option>
                    <option value="bg-gradient-gray">Gradient Gray</option>
                    <option value="bg-gradient-green">Gradient Green</option>
                    <option value="bg-gradient-hot-red">Gradient Hot Red</option>
                    <option value="bg-gradient-leaf">Gradient Leaf</option>
                    <option value="bg-gradient-navy">Gradient Navy</option>
                    <option value="bg-gradient-orange">Gradient Orange</option>
                    <option value="bg-gradient-pale-fuchsia">Gradient Pale Fuchsia</option>
                    <option value="bg-gradient-pale-grape">Gradient Pale Grape</option>
                    <option value="bg-gradient-pale-pink">Gradient Pale Pink</option>
                    <option value="bg-gradient-pale-purple">Gradient Pale Purple</option>
                    <option value="bg-gradient-pale-red">Gradient Pale Red</option>
                    <option value="bg-gradient-pale-sky">Gradient Pale Sky</option>
                    <option value="bg-gradient-pale-yellow">Gradient Pale Yellow</option>
                    <option value="bg-gradient-pink">Gradient Pink</option>
                    <option value="bg-gradient-primary">Gradient Primary</option>
                    <option value="bg-gradient-purple">Gradient Purple</option>
                    <option value="bg-gradient-red">Gradient Red</option>
                    <option value="bg-gradient-reverse-aqua">Gradient Reverse Aqua</option>
                    <option value="bg-gradient-reverse-ash">Gradient Reverse Ash</option>
                    <option value="bg-gradient-reverse-blue">Gradient Reverse Blue</option>
                    <option value="bg-gradient-reverse-dark">Gradient Reverse Dark</option>
                    <option value="bg-gradient-reverse-fuchsia">Gradient Reverse Fuchsia</option>
                    <option value="bg-gradient-reverse-grape">Gradient Reverse Grape</option>
                    <option value="bg-gradient-reverse-gray">Gradient Reverse Gray</option>
                    <option value="bg-gradient-reverse-green">Gradient Reverse Green</option>
                    <option value="bg-gradient-reverse-hot-red">Gradient Reverse Hot Red</option>
                    <option value="bg-gradient-reverse-leaf">Gradient Reverse Leaf</option>
                    <option value="bg-gradient-reverse-navy">Gradient Reverse Navy</option>
                    <option value="bg-gradient-reverse-orange">Gradient Reverse Orange</option>
                    <option value="bg-gradient-reverse-pale-purple">Gradient Reverse Pale Purple</option>
                    <option value="bg-gradient-reverse-pale-sky">Gradient Reverse Pale Sky</option>
                    <option value="bg-gradient-reverse-pink">Gradient Reverse Pink</option>
                    <option value="bg-gradient-reverse-primary">Gradient Reverse Primary</option>
                    <option value="bg-gradient-reverse-purple">Gradient Reverse Purple</option>
                    <option value="bg-gradient-reverse-red">Gradient Reverse Red</option>
                    <option value="bg-gradient-reverse-sky">Gradient Reverse Sky</option>
                    <option value="bg-gradient-reverse-soft-blue">Gradient Reverse Soft Blue</option>
                    <option value="bg-gradient-reverse-vintage-blue">Gradient Reverse Vintage Blue</option>
                    <option value="bg-gradient-reverse-violet">Gradient Reverse Violet</option>
                    <option value="bg-gradient-reverse-yellow">Gradient Reverse Yellow</option>
                    <option value="bg-gradient-sky">Gradient Sky</option>
                    <option value="bg-gradient-soft-blue">Gradient Soft Blue</option>
                    <option value="bg-gradient-vintage-blue">Gradient Vintage Blue</option>
                    <option value="bg-gradient-violet">Gradient Violet</option>
                    <option value="bg-gradient-yellow">Gradient Yellow</option>
                    <option value="bg-grape">Grape</option>
                    <option value="bg-gray">Gray</option>
                    <option value="bg-green">Green</option>
                    <option value="bg-header-grape">Header Grape</option>
                    <option value="bg-hot-red">Hot Red</option>
                    <option value="bg-hover-donate-pink">Hover Donate Pink</option>
                    <option value="bg-info">Info</option>
                    <option value="bg-leaf">Leaf</option>
                    <option value="bg-light">Light</option>
                    <option value="bg-line">Line</option>
                    <option value="bg-main-sky">Main Sky</option>
                    <option value="bg-map">Map</option>
                    <option value="bg-navy">Navy</option>
                    <option value="bg-opacity-10">Opacity 10</option>
                    <option value="bg-opacity-100">Opacity 100</option>
                    <option value="bg-opacity-25">Opacity 25</option>
                    <option value="bg-opacity-50">Opacity 50</option>
                    <option value="bg-opacity-75">Opacity 75</option>
                    <option value="bg-orange">Orange</option>
                    <option value="bg-overlay">Overlay</option>
                    <option value="bg-overlay-300">Overlay 300</option>
                    <option value="bg-overlay-400">Overlay 400</option>
                    <option value="bg-overlay-gradient">Overlay Gradient</option>
                    <option value="bg-overlay-light-300">Overlay Light 300</option>
                    <option value="bg-overlay-light-400">Overlay Light 400</option>
                    <option value="bg-overlay-light-500">Overlay Light 500</option>
                    <option value="bg-pale-aqua">Pale Aqua</option>
                    <option value="bg-pale-ash">Pale Ash</option>
                    <option value="bg-pale-blue">Pale Blue</option>
                    <option value="bg-pale-dark">Pale Dark</option>
                    <option value="bg-pale-fuchsia">Pale Fuchsia</option>
                    <option value="bg-pale-grape">Pale Grape</option>
                    <option value="bg-pale-gray">Pale Gray</option>
                    <option value="bg-pale-green">Pale Green</option>
                    <option value="bg-pale-leaf">Pale Leaf</option>
                    <option value="bg-pale-light">Pale Light</option>
                    <option value="bg-pale-navy">Pale Navy</option>
                    <option value="bg-pale-orange">Pale Orange</option>
                    <option value="bg-pale-pink">Pale Pink</option>
                    <option value="bg-pale-primary">Pale Primary</option>
                    <option value="bg-pale-purple">Pale Purple</option>
                    <option value="bg-pale-red">Pale Red</option>
                    <option value="bg-pale-sky">Pale Sky</option>
                    <option value="bg-pale-violet">Pale Violet</option>
                    <option value="bg-pale-white">Pale White</option>
                    <option value="bg-pale-yellow">Pale Yellow</option>
                    <option value="bg-pink">Pink</option>
                    <option value="bg-primary">Primary</option>
                    <option value="bg-purple">Purple</option>
                    <option value="bg-red">Red</option>
                    <option value="bg-secondary">Secondary</option>
                    <option value="bg-sky">Sky</option>
                    <option value="bg-soft-aqua">Soft Aqua</option>
                    <option value="bg-soft-ash">Soft Ash</option>
                    <option value="bg-soft-blue">Soft Blue</option>
                    <option value="bg-soft-dark">Soft Dark</option>
                    <option value="bg-soft-fuchsia">Soft Fuchsia</option>
                    <option value="bg-soft-grape">Soft Grape</option>
                    <option value="bg-soft-gray">Soft Gray</option>
                    <option value="bg-soft-green">Soft Green</option>
                    <option value="bg-soft-leaf">Soft Leaf</option>
                    <option value="bg-soft-light">Soft Light</option>
                    <option value="bg-soft-navy">Soft Navy</option>
                    <option value="bg-soft-orange">Soft Orange</option>
                    <option value="bg-soft-pink">Soft Pink</option>
                    <option value="bg-soft-primary">Soft Primary</option>
                    <option value="bg-soft-purple">Soft Purple</option>
                    <option value="bg-soft-red">Soft Red</option>
                    <option value="bg-soft-sky">Soft Sky</option>
                    <option value="bg-soft-violet">Soft Violet</option>
                    <option value="bg-soft-white">Soft White</option>
                    <option value="bg-soft-yellow">Soft Yellow</option>
                    <option value="bg-success">Success</option>
                    <option value="bg-transparent">Transparent</option>
                    <option value="bg-vintage-blue">Vintage Blue</option>
                    <option value="bg-violet">Violet</option>
                    <option value="bg-warning">Warning</option>
                    <option value="bg-white">White</option>
                    <option value="bg-yellow">Yellow</option>
                        </select>;
                break;

            case 'Text':

                break;

            default:
                break;
        }

        return createPortal(
            <div className="edit-overlay">
                <div className="edit-overlay-content">
                    <button className="edit-overlay-close" onClick={this.handleEditOverlayClose}>Close</button>
                    <h2>Edit {type} Element</h2>
                    {editContent}
                    <br />
            {
                (this.getElementDataFromPath([...contextMenuElementPath].slice(0, -1)).type == 'Row') 
                    ? <>
                        <input name="xs"type="number" min="1" max="12" 
                            value={getSubstringAfterLastHyphen(this.getElementColClassByPath(contextMenuElementPath, null) ?? '')}
                            onChange={(e) => this.setElementColClassByPath(contextMenuElementPath, null, e.target.value)}
                        ></input>
                        <label for="xs">Extra Small Screens</label>

                        <br />

                        <input name="sm" type="number" min="1" max="12" 
                            value={getSubstringAfterLastHyphen(this.getElementColClassByPath(contextMenuElementPath, 'sm') ?? '')}
                            onChange={(e) => this.setElementColClassByPath(contextMenuElementPath, 'sm', e.target.value)}
                        ></input>
                        <label for="sm">Small Screens</label>
                        <br />

                        <input name="md" type="number" min="1" max="12" 
                            value={getSubstringAfterLastHyphen(this.getElementColClassByPath(contextMenuElementPath, 'md') ?? '')}
                            onChange={(e) => this.setElementColClassByPath(contextMenuElementPath, 'md', e.target.value)}
                        ></input>
                        <label for="md">Medium Screens</label>

                        <br />
                        <input name="lg" type="number" min="1" max="12" 
                            value={getSubstringAfterLastHyphen(this.getElementColClassByPath(contextMenuElementPath, 'lg') ?? '')}
                            onChange={(e) => this.setElementColClassByPath(contextMenuElementPath, 'lg', e.target.value)}
                        ></input>
                        <label for="lg">Large Screens</label>

                <br />

                        <input name="xl" type="number" min="1" max="12" 
                            value={getSubstringAfterLastHyphen(this.getElementColClassByPath(contextMenuElementPath, 'xl') ?? '')}
                            onChange={(e) => this.setElementColClassByPath(contextMenuElementPath, 'xl', e.target.value)}
                        ></input>
                        <label for="xl">Extra Large Screens</label>

                        <br />
                        <br />
                        <br />

                    </>
                    : null
            }
                
                        <input name="className" type="text" 
                            value={elementData.props.className}
                            onChange={(e) => this.updateElementData(contextMenuElementPath, {...elementData, props: {
                                ...elementData.props,
                                className: e.target.value,
                            }})}
                        ></input>
                        <label for="className">Class Name (ADVANCED MAYBE DONT TOUCH)</label>

                </div>
                <div className="edit-overlay-background" onClick={this.handleEditOverlayClose}></div>
            </div>,
            document.body
        );
    }

    /**
     * Renders the EditContent component.
     * Calls the `render` method from the parent Content component and
     * adds additional UI elements for editing (e.g., context menus, section menus, edit overlays).
     */
    render() {
        const { documentData, error } = this.state;

        if (!documentData) {
            return <p>Loading...</p>;
        } else if (error) {
            return <p>An error has occurred.</p>;
        } else {
            return (
                <>
                    {(documentData.sections || []).map((child, i) => this.renderElement(child, [i]))}
                    {this.renderContextMenu()}
                    {this.renderEditOverlay()}
                    <section key="new-section">
                        <div className="container">
                            <center>
                                <button
                                    className="btn btn-outline-navy rounded-pill empty-section"
                                    onClick={(e) => this.enableContextMenu(e, 'newSection')}
                                >
                                    + New Section
                                </button>
                            </center>
                        </div>
                    </section>
                </>
            );
        }
    }
}

export default EditContent;

