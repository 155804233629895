// LoginButton.js
import React from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase.js'; // Update the path if needed

const LoginButton = () => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log("User signed in: ", result.user);
            })
            .catch((error) => {
                console.error("Error during sign-in: ", error);
            });
    };

    return (
        <button onClick={signInWithGoogle}>
            Log in with Google
        </button>
    );
};

export default LoginButton;

