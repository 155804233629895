import { ref, deleteObject } from "firebase/storage";
import {storage} from './firebase.js';

// Function to delete an image from Firebase Storage
const deleteImage = async (filePath) => {
  // Create a reference to the file to delete
  const imageRef = ref(storage, filePath);

  try {
    // Delete the file
    await deleteObject(imageRef);
    console.log("File deleted successfully!");
    alert("Image deleted successfully!");
  } catch (error) {
    console.error("Error deleting file:", error);
    alert("Failed to delete image. Please try again.");
  }
};

export default deleteImage;
