import React, { useRef, useState, useEffect } from 'react';
import { HashRouter, Routes, Route, useLocation } from 'react-router-dom';

import Header from './root/Header.js';
import Footer from './root/Footer.js';
import Content from './content/Content.js';
import EditContent from './content/ContentEditor.js';
import LoginButton from './root/LoginButton';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase.js';

const DynamicContent = () => {
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    const headerRef = useRef(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    setIsAdmin(!!idTokenResult.claims.admin);
                    setLoading(false);
                });
            } else {
                setIsAdmin(false);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="content-wrapper">
            {isAdmin ? <EditContent location={location}/> : <Content location={location} />}
            <Footer />
        </div>
    );
};

const App = () => {
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, []);

    return (
        <HashRouter>
            <Routes>
                <Route path="*" element={<DynamicContent />} />
            </Routes>
        </HashRouter>
    );
};

export default App;

