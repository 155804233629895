import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ImageUpload = ({ storage, onUploadSuccess }) => {
  const [imageFile, setImageFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!imageFile) {
      alert("Please select an image to upload.");
      return;
    }

    const storageRef = ref(storage, `images/${imageFile.name}`);

    try {
      setUploadStatus("Uploading...");
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setUploadStatus("Upload successful!");
      console.log("File available at", downloadURL);

      // Invoke the callback with the downloadURL
      if (onUploadSuccess) {
        onUploadSuccess(downloadURL);
      }
    } catch (error) {
      setUploadStatus("Upload failed. Please try again.");
      console.error("Upload error:", error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload Image</button>
      <p>{uploadStatus}</p>
    </div>
  );
};

export default ImageUpload;

