import { initializeApp } from 'firebase/app';
import { 
    getFirestore, 
    connectFirestoreEmulator, 
    doc, 
    getDoc, 
    setDoc 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

// Initialize Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectStorageEmulator(storage, "localhost", 9199);
}

// Function to get Firestore document as JSON
async function getDocumentAsJSON(collectionName, documentId) {
    try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.log('No such document!');
            return {};
        }
    } catch (e) {
        console.error('Error getting document:', e);
        return {};
    }
}

// Usage Example: Copy data from 'pages/aswov-events' to 'pages/aswov'
export { db, getDocumentAsJSON, auth, storage };

